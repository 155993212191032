import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import '../../../styles/global.css';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import db, { auth } from '../../../backend/firebase/firebaseConfig';
import { doc, getDoc, collection, query, where, getDocs, serverTimestamp, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import NavigationSideBar from '../NavigationSideBar';


const ActivateQRCode = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dashboard;
  const [qrCodeValue, setQRCodeValue] = useState('');

  const [activationDuration, setActivationDuration] = useState('5 days'); // Default value is 5 days

  const [senderLogo, setSenderLogo] = useState('');
  const [senderIdentityColor, setSenderIdentityColor] = useState('');

  const location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoggedIn(!!user);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
      const query = new URLSearchParams(location.search);
      const qrCodeIdFromURL = query.get('q');
      if (qrCodeIdFromURL) {
          setQRCodeValue(qrCodeIdFromURL);
      }
  }, [location]);
  
  const [identity] = useState('personal');
  const [recipientInfo, setRecipientInfo] = useState({
    recipientName: '',
    recipientStreet: '',
    recipientHouseNumber: '',
    recipientPostalCode: '',
    recipientCity: '',
    recipientState: '',
    recipientCountry: '',
    recipientPhoneNumber: '',
    recipientLogiHashID: ''
  });


  const [senderAddress] = useState({
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    state: '',
    country: ''
  });

  const [allIdentities, setAllIdentities] = useState([]);


  
  const navigate = useNavigate();

  const [identityOptions, setIdentityOptions] = useState([]);


  useEffect(() => {
  }, [navigate]);


  // Fetch user UID
  const userUID = auth.currentUser.uid;
  const [selectedIdentity, setSelectedIdentity] = useState('');

  useEffect(() => {
    fetchAllIdentities();
  }, []);

  const fetchAllIdentities = async () => {
    let identities = [];

    // Fetch from users collection
    const userData = await fetchUserData(userUID);
    if (userData) {
      identities.push({ label: `${userData.firstName} ${userData.lastName}`, value: userData.uid, type: t.personalIdentity });
    }

    // Fetch from other collections
    await Promise.all([
      fetchCollectionData('companies', 'companyUsers', 'companyAdmins', 'companyName', 'companyUID', t.companyIdentity),
      fetchCollectionData('branches', 'branchUsers', 'branchAdmins', 'branchName', 'branchUID', t.branchIdentity),
      fetchCollectionData('companyDepartments', 'departmentUsers', 'departmentAdmins', 'departmentName', 'departmentUID', t.departmentIdentity),
      fetchCollectionData('branchDepartments', 'departmentUsers', 'departmentAdmins', 'departmentName', 'departmentUID',  t.departmentIdentity)
    ]).then((results) => {
    results.forEach(result => identities = identities.concat(result));
    setIdentityOptions(identities);
    if (identities.length > 0) {
    setSelectedIdentity(identities[0].label);
    }
    });
    setAllIdentities(identities);
    };
    
    const fetchCollectionData = async (collectionName, userField, adminField, nameField, uidField, identityType) => {
    const qUser = query(collection(db, collectionName), where(userField, "array-contains", userUID));
    const qAdmin = query(collection(db, collectionName), where(adminField, "array-contains", userUID));
    const userSnapshot = await getDocs(qUser);
    const adminSnapshot = await getDocs(qAdmin);

    const identities = [];
userSnapshot.docs.forEach(doc => {
  const data = doc.data();
  identities.push({ label: data[nameField], value: data[uidField], type: identityType });
});
adminSnapshot.docs.forEach(doc => {
  const data = doc.data();
  if (!identities.some(identity => identity.value === data[uidField])) {
    identities.push({ label: data[nameField], value: data[uidField], type: identityType });
  }
});
return identities;
};

const fetchUserData = async (uid) => {
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => doc.data())[0];
  };


  const fetchAddressDetails = async () => {
    const selectedIdentityData = allIdentities.find(identity => identity.label === selectedIdentity);
    if (selectedIdentityData) {
      let collectionName;
      let fetchAdditionalFields = false; // to extract the current logo and identity color of the company

      // Determine the collection name based on the value prefix
      switch (true) {
        case selectedIdentityData.value.startsWith('COMDEP'):
          collectionName = 'companyDepartments';
          break;
        case selectedIdentityData.value.startsWith('BRANCHDEP'):
          collectionName = 'branchDepartments';
          break;
        case selectedIdentityData.value.startsWith('BRANCH'):
          collectionName = 'branches';
          break;
        case selectedIdentityData.value.startsWith('COMP'):
          collectionName = 'companies';
          fetchAdditionalFields = true; 
          break;
        default:
          collectionName = 'users';
      }
      
      const docRef = doc(db, collectionName, selectedIdentityData.value);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        senderAddress.street = data.street;
        senderAddress.houseNumber = data.houseNumber;
        senderAddress.postalCode = data.postalCode;
        senderAddress.city = data.city;
        senderAddress.state = data.state;
        senderAddress.country = data.country;

        // Update sender address state with fetched data

        if (fetchAdditionalFields) {
          setSenderLogo(data.companyLogo || '');
          setSenderIdentityColor(data.companyIdentityColor || '');
      }
      }
    }
  };

  useEffect(() => {
    if (selectedIdentity) {
      fetchAddressDetails();
    }
  }, [selectedIdentity, allIdentities]);
  
  const handleInputChange = (e) => {
    if (e.target.name === 'qrCodeValue') {
      setQRCodeValue(e.target.value);
    } else {
      setRecipientInfo({ ...recipientInfo, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) =>  {
    e.preventDefault();
    // Logic to handle QR code activation
    console.log('QR Code Value:', qrCodeValue);
    console.log('Identity:', identity);
    console.log('Recipient Info:', recipientInfo);
    console.log('Activation Duration:', activationDuration);

      // Query the 'inactiveQRCodes' collection
  const qrCodeRef = doc(db, "inactiveQRCodes", qrCodeValue);
  const qrCodeDoc = await getDoc(qrCodeRef);

  if (qrCodeDoc.exists()) {

    // Fetch senderUID based on selectedIdentityData.value
    const selectedIdentityData = allIdentities.find(identity => identity.label === selectedIdentity);
    let senderUID = '';
    if (selectedIdentityData) {
        senderUID = selectedIdentityData.value;
    }

    // Calculate deactivationTimestamp
    let deactivationTimestamp = null;
    if (activationDuration !== 'forever') {
      const duration = parseInt(activationDuration.split(' ')[0], 10); // Extract the number from the duration
      deactivationTimestamp = new Date(Date.now() + duration * 24 * 60 * 60 * 1000); // Calculate future date
    }

    // Prepare the updated data
    const updatedData = {
      senderName: selectedIdentity, // Assuming identity is the sender's name
      senderStreet: senderAddress.street,
      senderHouseNumber: senderAddress.houseNumber,
      senderPostalCode: senderAddress.postalCode,
      senderCity: senderAddress.city,
      senderState: senderAddress.state,
      senderCountry: senderAddress.country,
      recipientName: recipientInfo.recipientName,
      recipientStreet:recipientInfo.recipientStreet ,
      recipientHouseNumber:recipientInfo.recipientHouseNumber ,
      recipientPostalCode:recipientInfo.recipientPostalCode ,
      recipientCity:recipientInfo.recipientCity ,
      recipientState:recipientInfo.recipientState ,
      recipientCountry:recipientInfo.recipientCountry ,
      recipientPhoneNumber: recipientInfo.recipientPhoneNumber,
      recipientLogiHashID: recipientInfo.recipientLogiHashID,

      creationTimestamp: qrCodeDoc.data().creationTimestamp,
      deactivateBy: qrCodeDoc.data().deactivateBy,
      notificationTime: '',
      status: '',

      lastChangeTimestamp: serverTimestamp(),
      qrCodeProduct: qrCodeDoc.data().qrCodeProduct,
      qrCodeProductSize: qrCodeDoc.data().qrCodeProductSize,
      IDOfOwner: userUID,
      active: true,
      new: false,
      senderUID: senderUID,
      senderLogo: senderLogo, // Add senderLogo here
      senderIdentityColor: senderIdentityColor, // Add senderIdentityColor here
      qrCodeWarnings: [],
      deactivationTimestamp: deactivationTimestamp ? deactivationTimestamp : null,
      numberOfScans: 0,
      codeType: qrCodeDoc.data().codeType ? qrCodeDoc.data().codeType : 'QRCode',
    };

    // Update the document in 'inactiveQRCodes'
    await updateDoc(qrCodeRef, updatedData);

    // Move the document to 'activeQRCodes'
    const activeQRCodesRef = doc(db, "activeQRCodes", qrCodeValue);
    await setDoc(activeQRCodesRef, { ...qrCodeDoc.data(), ...updatedData });

    // Delete the document from 'inactiveQRCodes'
    await deleteDoc(qrCodeRef);

    console.log('QR Code activated successfully.');
    navigate(`/dashboard/activeQRCodes`);
  } else {
    alert(t.QRCodeActiveOrNotExist);
  }

  alert(t.paymentAlertInOfficialVersion);

  window.location.reload();

};



  return (
    <div className="white-page-content">
    <div className="activate-qr-code-page">
    <NavigationSideBar/>
      <h1>{t.title}</h1>
      <p>{t.description}</p>
      <form onSubmit={handleSubmit} className="qr-code-form">
        <div className="form-group">
          <input
            type="text"
            name="qrCodeValue"
            value={qrCodeValue}
            onChange={handleInputChange}
            placeholder={t.placeholder}
          />

        </div>

        <div className="form-group">
        <select name="activationDuration" onChange={(e) => setActivationDuration(e.target.value)} value={activationDuration} className="identity-select">
          <option value="5 days">{t.activationDurationLabel+': ' + t.fivedays}</option>
          <option value="10 days">{t.activationDurationLabel+': ' + t.tendays}</option>
          <option value="15 days">{t.activationDurationLabel+': ' + t.fifteendays}</option>
          <option value="30 days">{t.activationDurationLabel+': ' + t.thirtydays}</option>
          <option value="forever">{t.activationDurationLabel+': ' + t.forever}</option>
        </select>
        </div>

        <div className="form-group">
        <select name="senderIdentity" onChange={(e) => setSelectedIdentity(e.target.value)} value={selectedIdentity} className="identity-select">
  {identityOptions.map(option => (
  <option key={option.value} value={option.label}>
  {option.label}({option.type})
  </option>
  ))}
          </select>
        </div>
        {activationDuration !== 'forever' ? (
            <>
        <div className="form-group">
          <input type="text" name="recipientName" placeholder={t.recipientName + t.optional} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input type="text" name="recipientStreet" placeholder={t.recipientStreet + t.optional} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input type="text" name="recipientHouseNumber" placeholder={t.recipientHouseNumber + t.optional} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input type="text" name="recipientPostalCode" placeholder={t.recipientPostalCode + t.optional} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input type="text" name="recipientCity" placeholder={t.recipientCity + t.optional} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input type="text" name="recipientState" placeholder={t.recipientState + t.optional} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input type="text" name="recipientCountry" placeholder={t.recipientCountry + t.optional} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input type="text" name="recipientPhoneNumber" placeholder={t.recipientPhoneNumber + t.optional} onChange={handleInputChange} />
        </div>

        </>
          ) : (
            <>
              <div className="form-group">
                <input type="text" name="recipientName" placeholder={t.recipientName + t.required} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="recipientStreet" placeholder={t.recipientStreet + t.required} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="recipientHouseNumber" placeholder={t.recipientHouseNumber + t.required} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="recipientPostalCode" placeholder={t.recipientPostalCode + t.required} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="recipientCity" placeholder={t.recipientCity + t.required} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="recipientState" placeholder={t.recipientState + t.required} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="recipientCountry" placeholder={t.recipientCountry + t.required} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="recipientPhoneNumber" placeholder={t.recipientPhoneNumber + t.required} onChange={handleInputChange} required />
              </div>
            </>
          )}

        <div className="form-group">
          <input type="text" name="recipientLogiHashID" placeholder={t.recipientLogiHashID + t.optional} onChange={handleInputChange} />
        </div>

        <button type="submit" className="activate-button">{t.activateButtonLabel}</button>
      </form>
    </div>
    <p className="mobile-app-note">{t.mobileAppNote}</p>
  </div>

  );
};

export default ActivateQRCode;
